import * as Yup from "yup";

// validation for tagValue
const tagValues = Yup.array()
  .of(
    Yup.object().shape({
      tagValue: Yup.string(),
    })
  )
  .test("has-value", "Tag Value is required.", function (value) {
    const hasValue = value.some(
      (obj) => obj.tagValue !== undefined && obj.tagValue !== ""
    );
    return hasValue;
  });

export const tagFormValidation = () => {
  const newSchema = Yup.object().shape({
    tagValues: tagValues,
    tagName: Yup.string().required("Tag is required"),
  });
  return newSchema;
};

export const propertyTypeFormValidation = () => {
  const newSchema = Yup.object().shape({
    propertyType: Yup.string().required("Property Type is required"),
  });
  return newSchema;
};


export const qcInFormValidation = () => {
  const newSchema = Yup.object().shape({
    qcTo: Yup.string().required("QC To is required"),
    companyId: Yup.string().required("Company is required"),
    demandIds: Yup.array().required(
      "Scan minimum one product."
    )
  });
  return newSchema;
};

export const qcApprovedFormValidation = () => {
  const newSchema = Yup.object().shape({
    companyId: Yup.string().required("Company is required"),
    demandIds: Yup.array().required(
      "Scan minimum one product."
    )
  });
  return newSchema;
};

export const qcRejectedFormValidation = () => {
  const newSchema = Yup.object().shape({
    companyId: Yup.string().required("Company is required"),
    demandIds: Yup.array().required(
      "Scan minimum one product."
    )
  });
  return newSchema;
};



export const goodsCategoryFormValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  return newSchema;
};

export const userCouponsFormValidation = () => {
  const newSchema = Yup.object().shape({
    locationId: Yup.string().required("Location is required"),
    userId: Yup.string().required("Location is required")
  });
  return newSchema;
};

export const shipmentMethodsFormValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  return newSchema;
};

export const locationLogisticsProviderMapsFormValidation = () => {
  const newSchema = Yup.object().shape({
    logisticsProviderId: Yup.string().required("Logistics Provider is required"),
    shipmentMethodId: Yup.string().required("Shipment Method is required"),
    sourceLocationId: Yup.string().required("Source Location is required")
      .test('notEqual', 'Source Location and Destination Location should not be the same', function (value) {
        const { destinationLocationId } = this.parent;
        return value !== destinationLocationId;
      })
  });
  return newSchema;
};

export const relationTypeFormValidation = () => {
  const newSchema = Yup.object().shape({
    relationType: Yup.string().required("Relation Type is required"),
  });
  return newSchema;
};

export const cadFormValidationForm = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    fileName: Yup.string().required("File Name is required"),
  });
  return newSchema;
};

export const findingFormValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    findingTypeId: Yup.string().required("Finding Type is required"),
  });
  return newSchema;
};

export const StockTrayValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    locationId: Yup.string().required("location is required"),
  });
  return newSchema;
};

export const InventoryTrayValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    locationId: Yup.string().required("location is required"),
  });
  return newSchema;
};

export const StockTagValidation = () => {
  const newSchema = Yup.object().shape({
    stockTrayId: Yup.string().required("Stock Tray is required"),
    locationId: Yup.string().required("Location is required"),
  });
  return newSchema;
};

export const StockProductValidation = () => {
  const newSchema = Yup.object().shape({
    stockTagId: Yup.string().required("Stock Tag is required"),
    jewelleryProductId: Yup.string().required("Jewellery Product is required"),
  });
  return newSchema;
};

export const CurrencyValidation = () => {
  const newSchema = Yup.object().shape({
    currency: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      code: Yup.string().required("Code is required"),
      symbol: Yup.string().required("Symbol is required"),
    }),
  });
  return newSchema;
};

export const PaymentMethodValidation = () => {
  const newSchema = Yup.object().shape({
  });
  return newSchema;
};

export const InStorePickupValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    locationId: Yup.string().required("location is required"),
  });
  return newSchema;
};

export const PoPricingValidation = () => {
  const newSchema = Yup.object().shape({
    totalAmount: Yup.number().required("Total Amount is required"),
    shippingPrice: Yup.number().required("Shipping Price is required"),
    insurance: Yup.number().required("Insurance is required"),
    invoiceTotalAmount: Yup.number().required(
      "Invoice Total Amount is required"
    ),
    tax: Yup.number().required("Tax is required"),
    fromCurrencyCode: Yup.string().required("From Currency Code is required"),
    toCurrencyCode: Yup.string().required("To Currency Code is required"),
    currencyRate: Yup.number().required("Currency Rate is required"),
    convertedValue: Yup.number().required("Converted Value is required"),
  });
  return newSchema;
};

export const ShippingMethodValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  return newSchema;
};

export const CouponsValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    code: Yup.string().required("Code is required"),
    startDate: Yup.date()
      .required("Start date is required")
      .max(Yup.ref("endDate"), "Start date must be before end date"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
  });
  return newSchema;
};
export const PromotionsValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // conditionPropertyValues: Yup.string().required(
    //   "condition Property Values is required"
    // ),
    startDate: Yup.date()
      .required("Start date is required")
      .max(Yup.ref("endDate"), "Start date must be before end date"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
  });
  return newSchema;
};

export const CouponsTypeValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  return newSchema;
};

export const ConditionPropertyOperatorsValidation = () => {
  const newSchema = Yup.object().shape({
    operatorName: Yup.string().required("Operator Name is required"),
    displayName: Yup.string().required("Display Name is required"),
  });
  return newSchema;
};

export const ConditionPropertiesValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  return newSchema;
};

export const DiscountTypeValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  return newSchema;
};

export const MetalCostValidation = () => {
  const newSchema = Yup.object().shape({
    metalId: Yup.string().required("Metal is required"),
    costInUsd: Yup.string().required("Cost is required")
  });
  return newSchema;
};

export const FindingCostValidation = () => {
  const newSchema = Yup.object().shape({
    findingId: Yup.string().required("Finding is required"),
    costInUsd: Yup.string().required("Cost is required")
  });
  return newSchema;
};

export const MetalMarkUpValidation = () => {
  const newSchema = Yup.object().shape({
    metalId: Yup.string().required("Metal is required"),
    storeId: Yup.string().required("Store is required"),
    collectionTypeId: Yup.string().required("Collection Type is required"),
    weightFrom: Yup.string().required("Weight From is required"),
    weightTo: Yup.string()
      .test(
        "is-greater",
        "Weight To must be greater than or equal to Weight From",
        function (value) {
          const weightFrom = this.parent.weightFrom;
          const weightTo = value;
          return parseInt(weightTo, 10) >= parseInt(weightFrom, 10);
        }
      )
      .required("Weight To is required"),
    fixedUsdMarkup: Yup.string().required("Fixed Markup is required"),
    percentageMarkup: Yup.string().required("Percentage Markup is required"),
  });
  return newSchema;
};

export const StoreCollectionTypeMarkupValidation = () => {
  const newSchema = Yup.object().shape({
    storeId: Yup.string().required("Store is required"),
    collectionTypeId: Yup.string().required("Collection Type is required"),
    priceFrom: Yup.string().required("Price From is required"),
    priceTo: Yup.string()
      .test(
        "is-greater",
        "Price To must be greater than or equal to Weight From",
        function (value) {
          const priceFrom = this.parent.priceFrom;
          const priceTo = value;
          return parseInt(priceTo, 10) >= parseInt(priceFrom, 10);
        }
      )
      .required("Price To is required"),
    fixedUsdMarkup: Yup.string().required("Fixed Markup is required"),
    percentageMarkup: Yup.string().required("Percentage Markup is required"),
  });
  return newSchema;
};

export const collectionFormValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    collectionTypeId: Yup.string().required("Collection Type is required"),
  });
  return newSchema;
};

export const pettyCashValidation = () => {
  const newSchema = Yup.object().shape({
    transactionAmount: Yup.number().required("Transaction Amount is required"),
  });
  return newSchema;
};

export const PoLineItemValidation = () => {
  const newSchema = Yup.object().shape({
    receivedTotalQuantity: Yup.number().required(
      "Received Total Quantity is required"
    ),
    quantity: Yup.number().required("Quantity is required"),
  });
  return newSchema;
};

export const shippingFormValidation = () => {
  return Yup.object().shape({    
    state: Yup.string().required("State is required")
  });
};

export const metalFormValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    metalTypeId: Yup.string().required("Metal Type is required"),
  });
  return newSchema;
};

export const nameValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    allowedStones: Yup.array().of(
      Yup.object().shape({
        stoneId: Yup.string().required("Stone ID is required"),
        minQty: Yup.number().required("Minimum quantity is required"),
        maxQty: Yup.number()
          .required("Maximum quantity is required")
          .min(
            Yup.ref("minQty"),
            "Max quantity should be greater than or equal to Min quantity"
          ),
      })
    ),
  });
  return newSchema;
};

export const componentValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    allowedStones: Yup.array().of(
      Yup.object().shape(
        {
          min: Yup.string().when("min", {
            is: (exists) => !!exists,
            then: Yup.string().test(
              "has-value",
              "Only Positive values are allowed.",
              function (value) {
                const hasValue = !isNaN(value);
                return hasValue;
              }
            ),
            otherwise: Yup.string(),
          }),
          max: Yup.string().when("max", {
            is: (exists) => !!exists,
            then: Yup.string().test(
              "has-value",
              "Only Positive values are allowed.",
              function (value) {
                const hasValue = !isNaN(value);
                return hasValue;
              }
            ),
            otherwise: Yup.string(),
          }),
        },
        [
          ["max", "max"],
          ["min", "min"],
        ]
      )
    ),
  });
  return newSchema;
};


export const componentTypeValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    allowedCollectionTypeMaps: Yup.array().of(
      Yup.object().shape({
        minQty: Yup.number().required("Minimum quantity is required"),
        maxQty: Yup.number()
          .required("Maximum quantity is required")
          .min(
            Yup.ref("minQty"),
            "Max quantity should be greater than or equal to Min quantity"
          ),
      })
    ),
    allowedCollectionTypeComponentTypeMaps: Yup.array().of(
      Yup.object().shape({
        minQty: Yup.number().required("Minimum quantity is required"),
        maxQty: Yup.number()
          .required("Maximum quantity is required")
          .min(
            Yup.ref("minQty"),
            "Max quantity should be greater than or equal to Min quantity"
          ),
      })
    ),
    collectionTypeAllowedFindingMap: Yup.array().of(
      Yup.object().shape({
        minQty: Yup.number().required("Minimum quantity is required"),
        maxQty: Yup.number()
          .required("Maximum quantity is required")
          .min(
            Yup.ref("minQty"),
            "Max quantity should be greater than or equal to Min quantity"
          ),
      })
    ),
  });
  return newSchema;
};


export const OrderStatesValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("Name is required"),
    stateColour: Yup.string().required("Colour is required"),
    stateCode: Yup.string().required("Code is required"),
  });
  return newSchema;
};

export const ShipmentTrackingStatesValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("Name is required"),
    stateColour: Yup.string().required("Colour is required"),
  });
  return newSchema;
};

export const VendorProductStatesValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("Name is required"),
    stateCode: Yup.string().required("Code is required"),
  });
  return newSchema;
};

export const ProcurementStateValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("Name is required"),
    stateCode: Yup.string().required("Code is required"),
  });
  return newSchema;
};

export const addOnFormValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    addonTypeId: Yup.string().required("Add-On Type is required"),
  });
  return newSchema;
};

export const serviceFormValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    serviceTypeId: Yup.string().required("Service Type is required"),
  });
};

export const stoneFormValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    stoneTypeId: Yup.string().required("Stone Type is required"),
  });
  return newSchema;
};

export const familyFormValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
};
export const componentFormValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    componentTypeId: Yup.string().required("Component Type is required"),
    componentStoneSets: Yup.array().of(
      Yup.object().shape({
        componentStonePropertyValueMaps: Yup.array().of(
          Yup.object().shape(
            {
              qty: Yup.string().when("qty", {
                is: (exists) => !!exists,
                then: Yup.string().test(
                  "has-value",
                  "Only Positive values are allowed.",
                  function (value) {
                    const hasValue = !isNaN(value);
                    return hasValue;
                  }
                ),
                otherwise: Yup.string(),
              }),
            },
            [["qty", "qty"]]
          )
        ),
      })
    ),

    componentFinding: Yup.array().of(
      Yup.object().shape(
        {
          qty: Yup.string().when("qty", {
            is: (exists) => !!exists,
            then: Yup.string().test(
              "has-value",
              "Only Positive values are allowed.",
              function (value) {
                const hasValue = !isNaN(value);
                return hasValue;
              }
            ),
            otherwise: Yup.string(),
          }),
        },
        [["qty", "qty"]]
      )
    ),
  });
};

export const componentFormBulkUpdatsValidation = () => {
  return Yup.object().shape({
    componentStoneSets: Yup.array().of(
      Yup.object().shape({
        componentStonePropertyValueMaps: Yup.array().of(
          Yup.object().shape(
            {
              qty: Yup.string().when("qty", {
                is: (exists) => !!exists,
                then: Yup.string().test(
                  "has-value",
                  "Only Positive values are allowed.",
                  function (value) {
                    const hasValue = !isNaN(value);
                    return hasValue;
                  }
                ),
                otherwise: Yup.string(),
              }),
            },
            [["qty", "qty"]]
          )
        ),
      })
    )
  });
};

export const finishValidation = () => {
  const newSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    loss: Yup.number().required("Loss is required"),
  });
  return newSchema;
};

const getPropertiesVal = (selectedPropertyType) => {
  if (["list"].includes(selectedPropertyType?.toLowerCase())) {
    return { value: Yup.string() };
  } else if (["text"].includes(selectedPropertyType?.toLowerCase())) {
    return {
      min: Yup.number(),
      max: Yup.number(),
    };
  }
  return {
    min: Yup.number(),
    max: Yup.number(),
    precision: Yup.number(),
  };
};

const valPropertyValue = (selectedPropertyType, type) =>
  Yup.array().of(
    Yup.object().shape({
      [type]: Yup.array()
        .of(Yup.object(getPropertiesVal(selectedPropertyType)))
        .test(
          "has-value",
          "Min Value should be Less then Max value",
          function (value) {
            const hasValue = value?.every(
              (obj) =>
                ((obj.min === undefined || obj.min === "") &&
                  (obj.max === undefined || obj.max === "")) ||
                obj.min <= obj.max
            );
            return hasValue;
          }
        ),
    })
  );

export const metalPropertiesFormValidationSchema = (selectedPropertyType) =>
  Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    typeId: Yup.string().required("Metal Type is required"),
    metalPropertyValues: valPropertyValue(
      selectedPropertyType,
      "metalPropertyValuesTranslates"
    ),
  });

export const collectionsPropertiesFormValidationSchema = (
  selectedPropertyType
) =>
  Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    propertyTypeId: Yup.string().required("Collection Type is required"),
    collectionsPropertyValues: valPropertyValue(
      selectedPropertyType,
      "collectionsPropertyValuesTranslates"
    ),
  });

export const addOnPropertiesFormValidationSchema = (selectedPropertyType) =>
  Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    propertyTypeId: Yup.string().required("Add-on Type is required"),
    addonPropertyValues: valPropertyValue(
      selectedPropertyType,
      "addonPropertyValuesTranslates"
    ),
  });

export const stonePropertiesFormValidationSchema = (selectedPropertyType) =>
  Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    typeId: Yup.string().required("Stone Type is required"),
    stonePropertyValues: valPropertyValue(
      selectedPropertyType,
      "stonePropertyValuesTranslates"
    ),
  });

export const findingPropertiesFormValidationSchema = (selectedPropertyType) =>
  Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    typeId: Yup.string().required("Service Type is required"),
    findingPropertyValues: valPropertyValue(
      selectedPropertyType,
      "findingPropertyValuesTranslates"
    ),
  });

export const servicePropertiesFormValidationSchema = (selectedPropertyType) =>
  Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    typeId: Yup.string().required("Service Type is required"),
    servicePropertyValues: valPropertyValue(
      selectedPropertyType,
      "servicePropertyValuesTranslates"
    ),
  });

export const familyPropertiesFormValidationSchema = (selectedPropertyType) => {
  return Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    propertyTypeId: Yup.string(),
    familyPropertyValues: valPropertyValue(
      selectedPropertyType,
      "familyPropertyValueTranslates"
    ),
  });
};
export const componentPropertiesFormValidationSchema = (
  selectedPropertyType
) => {
  return Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    propertyTypeId: Yup.string(),
    componentPropertyValues: valPropertyValue(
      selectedPropertyType,
      "componentPropertyValueTranslates"
    ),
  });
};
export const setPropertiesFormValidationSchema = (selectedPropertyType) =>
  Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^.]*$/, "Text should not include a dot (.)"),
    typeId: Yup.string().required("Set Type is required"),
    componentSetPropertyValues: valPropertyValue(
      selectedPropertyType,
      "componentSetPropertyValuesTranslates"
    ),
  });

export const featureOptionFormValidationSchema = () =>
  Yup.object({
    name: Yup.string().required("Name is required"),
    isChooseSpecificDiamond: Yup.boolean()
      .test('oneOfTwo', 'Only one of Quick Delivery or Choose Specific Diamond should be true', function (value) {
        const { isQuickDelivery, isChooseSpecificDiamond } = this.parent;
        return (isQuickDelivery && isChooseSpecificDiamond) !== true;
      })
  });

export const customerFormValidation = (action) =>
  Yup.object({
    title: Yup.string().required("Title is required").matches(/^[A-Za-z\s]+$/, "Title should not contain numbers"),
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    storeId: Yup.string().when([], {
      is: () => action !== "edit",
      then: Yup.string().required("Store is required"),
      otherwise: Yup.string().notRequired(),
    })
  });

export const customerEnquiriyRequestFormValidation = (action) =>
  Yup.object({
    name: Yup.string().required("Name is required"),
    storeId: Yup.string().when([], {
      is: () => action !== "edit",
      then: Yup.string().required("Store is required"),
      otherwise: Yup.string().notRequired(),
    }),
  });

export const customerEnquiriyFormValidation = (action) =>
  Yup.object({
    name: Yup.string().required("Name is required"),
    surname: Yup.string().required("Sur Name is required"),
    email: Yup.string().nullable().email(),
    // phoneNumber: Yup.string().matches(
    //   /^[0-9]{10}$/,
    //   "Phone number must be exactly 10 digits"
    // ),
    customerId: Yup.string().required("Customer is required"),
  });

export const BespokeQuestionariesFormValidation = () =>
  Yup.object({
    question: Yup.string().required("Question is required"),
    storeId: Yup.string().required("Store is required"),
  });

export const pageConfigFormValidation = () => {
  const newSchema = Yup.object().shape({
    meta: Yup.object()
      .shape({
        urlSlug: Yup.string().required("Required"),
        metaTranslates: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string().required("Required"),
              description: Yup.string().required("Required"),
              seoKeyword: Yup.string().required("Required"),
              translateId: Yup.string().required("Required"),
            })
          )
          .required("Required"),
      })
      .required("Required"),
  });
  return newSchema;
};

export const countryFormValidation = () =>
  Yup.object().shape({
  });

export const locationFormValidation = () =>
  Yup.object().shape({
    telephone: Yup.string()
      .matches(/^[0-9]+$/, "Telephone must only contain numbers"),
    fax: Yup.string()
      .matches(/^[0-9]+$/, "Fax must only contain numbers"),
    open_time: Yup.string()
      .matches(/^[0-9]+$/, "Open Time must only contain numbers")
  });

export const StoreValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().nullable().required("Name is required"),
    site_url: Yup.string().nullable().required("Site URL is required"),
    companyEmail: Yup.string().nullable().email("Company email must be a valid email"),
  });
  return newSchema;
};

export const PRLineItemValidation = () => {
  const newSchema = Yup.object().shape({
    quantity: Yup.string().nullable().required("Quantity is required"),
  });
  return newSchema;
};

export const CustomerAddressValidation = () => {
  const newSchema = Yup.object().shape({
    email: Yup.string().nullable().email(),
  });
  return newSchema;
};

export const pricingMarkupValidation = () => {
  const newSchema = Yup.object().shape({
    // storeId: Yup.string().required("Store is required"),
    name: Yup.string().required("Name is required"),
    // Todo: remove later
    // fixedUsdMarkup: Yup.number().test({
    //   test: function (value) {
    //     const percentageMarkup = this.resolve(Yup.ref("percentageMarkup"));
    //     return (
    //       (value !== undefined && value > 0) ||
    //       (percentageMarkup !== undefined && percentageMarkup > 0)
    //     );
    //   },
    //   message: "Either Fixed Markup or Percentage Markup is required",
    // }),
    // percentageMarkup: Yup.number().test({
    //   test: function (value) {
    //     const fixedUsdMarkup = this.resolve(Yup.ref("fixedUsdMarkup"));
    //     return (
    //       (value !== undefined && value > 0) ||
    //       (fixedUsdMarkup !== undefined && fixedUsdMarkup > 0)
    //     );
    //   },
    //   message: "Either Fixed Markup or Percentage Markup is required",
    // }),
  });
  return newSchema;
};

export const pricingCalculatorValidation = () => {
  const newSchema = Yup.object().shape({
    storeId: Yup.string().required("Store is required"),
    metals: Yup.array().of(
      Yup.object().shape({
        metalId: Yup.string().required("Metal is required"),
        weight: Yup.number().required("Weight is required"),
      })
    ),
    stones: Yup.array()
      .of(
        Yup.object().shape({
          stoneId: Yup.string().required("Stone is required"),
          quantity: Yup.number().required("Quantity is required"),
          vendorId: Yup.string().required("Vendor is required"),
          positionId: Yup.string().required("Position is required"),
          stonePropertyMap: Yup.array().of(
            Yup.object().shape({
              value: Yup.lazy((value, { parent }) => {
                if (
                  value &&
                  (parent.propertyType === "multiSelect" ||
                    parent.propertyType === "list")
                ) {
                  return Yup.array()
                    .of(
                      Yup.object().shape({
                        active: Yup.boolean().required(),
                      })
                    )
                    .test(
                      "hasOneActive",
                      "At least one option must be selected",
                      (value) => {
                        const activeCount = value.filter(
                          (item) => item.active === true
                        ).length;
                        return activeCount > 0;
                      }
                    );
                } else if (value && parent.propertyType === "number") {
                  return Yup.array().of(
                    Yup.object().shape({
                      value: Yup.number()
                        .moreThan(0)
                        .required("Value should be greater then 0"),
                    })
                  );
                }
                return Yup.mixed();
              }),
            })
          ),
        })
      )
      .required("Stones are required"),
  });
  return newSchema;
};

export const MetalIncentivesValidation = () => {
  const newSchema = Yup.object().shape({
    metalId: Yup.string().required("Metal is required"),
    storeId: Yup.string().required("Store is required"),
    metalTypeId: Yup.string().required("Metal Type is required"),
    weightFrom: Yup.string().required("Weight From is required"),
    weightTo: Yup.string()
      .test(
        "is-greater",
        "Weight To must be greater than or equal to Weight From",
        function (value) {
          const weightFrom = this.parent.weightFrom;
          const weightTo = value;
          return parseInt(weightTo, 10) >= parseInt(weightFrom, 10);
        }
      )
      .required("Weight To is required"),
    usdSellingPriceFrom: Yup.string().required("From is required"),
    usdSellingPriceTo: Yup.string()
      .test(
        "is-greater",
        "Price To must be greater than or equal to Price From",
        function (value) {
          const usdSellingPriceFrom = this.parent.usdSellingPriceFrom;
          const usdSellingPriceTo = value;
          return (
            parseInt(usdSellingPriceTo, 10) >= parseInt(usdSellingPriceFrom, 10)
          );
        }
      )
      .required("Price To is required"),
    fixedUsdPayment: Yup.string().required("Fixed Usd Payment is required"),
    percentage: Yup.string().required("Percentage is required"),
  });
  return newSchema;
};

export const StoneIncentivesValidation = () => {
  const newSchema = Yup.object().shape({
    stoneId: Yup.string().required("Stone is required"),
    storeId: Yup.string().required("Store is required"),
    stoneTypeId: Yup.string().required("Stone Type is required"),
    usdSellingPriceFrom: Yup.string().required("From is required"),
    usdSellingPriceTo: Yup.string()
      .test(
        "is-greater",
        "Price To must be greater than or equal to Price From",
        function (value) {
          const usdSellingPriceFrom = this.parent.usdSellingPriceFrom;
          const usdSellingPriceTo = value;
          return (
            parseInt(usdSellingPriceTo, 10) >= parseInt(usdSellingPriceFrom, 10)
          );
        }
      )
      .required("Price To is required"),
    fixedUsdPayment: Yup.string().required("Fixed Usd Payment is required"),
    percentage: Yup.string().required("Percentage is required"),
  });
  return newSchema;
};

export const CollectionIncentivesValidation = () => {
  const newSchema = Yup.object().shape({
    collectionId: Yup.string().required("Collection is required"),
    storeId: Yup.string().required("Store is required"),
    collectionTypeId: Yup.string().required("Collection Type is required"),
    usdSellingPriceFrom: Yup.string().required("From is required"),
    usdSellingPriceTo: Yup.string()
      .test(
        "is-greater",
        "Price To must be greater than or equal to Price From",
        function (value) {
          const usdSellingPriceFrom = this.parent.usdSellingPriceFrom;
          const usdSellingPriceTo = value;
          return (
            parseInt(usdSellingPriceTo, 10) >= parseInt(usdSellingPriceFrom, 10)
          );
        }
      )
      .required("Price To is required"),
    fixedUsdPayment: Yup.string().required("Fixed Usd Payment is required"),
    percentage: Yup.string().required("Percentage is required"),
  });
  return newSchema;
};

export const ServiceIncentivesValidation = () => {
  const newSchema = Yup.object().shape({
    serviceId: Yup.string().required("Service is required"),
    storeId: Yup.string().required("Store is required"),
    usdSellingPriceFrom: Yup.string().required("From is required"),
    usdSellingPriceTo: Yup.string()
      .test(
        "is-greater",
        "Price To must be greater than or equal to Price From",
        function (value) {
          const usdSellingPriceFrom = this.parent.usdSellingPriceFrom;
          const usdSellingPriceTo = value;
          return (
            parseInt(usdSellingPriceTo, 10) >= parseInt(usdSellingPriceFrom, 10)
          );
        }
      )
      .required("Price To is required"),
    fixedUsdPayment: Yup.string().required("Fixed Usd Payment is required"),
    percentage: Yup.string().required("Percentage is required"),
  });
  return newSchema;
};

export const InstockJewelleryProductIncentivesValidation = () => {
  const percentageRangeRegex = /\b(?:100|[1-9]?\d)\b/;
  const newSchema = Yup.object().shape({
    stockTagId: Yup.string().required("Stock Tag is required"),
    storeId: Yup.string().required("Store is required"),
    usdSellingPriceFrom: Yup.string().required("From is required"),
    usdSellingPriceTo: Yup.string()
      .test(
        "is-greater",
        "Price To must be greater than or equal to Price From",
        function (value) {
          const usdSellingPriceFrom = this.parent.usdSellingPriceFrom;
          const usdSellingPriceTo = value;
          return (
            parseInt(usdSellingPriceTo, 10) >= parseInt(usdSellingPriceFrom, 10)
          );
        }
      )
      .required("Price To is required"),
    fixedUsdPayment: Yup.string().required("Fixed Usd Payment is required"),
    percentage: Yup.string().matches(percentageRangeRegex, 'Percentage must be a number between 1 and 100').required("Percentage is required"),
  });
  return newSchema;
};

export const AddonIncentivesValidation = () => {
  const newSchema = Yup.object().shape({
    addonId: Yup.string().required("Addon is required"),
    storeId: Yup.string().required("Store is required"),
    usdSellingPriceFrom: Yup.string().required("From is required"),
    usdSellingPriceTo: Yup.string()
      .test(
        "is-greater",
        "Price To must be greater than or equal to Price From",
        function (value) {
          const usdSellingPriceFrom = this.parent.usdSellingPriceFrom;
          const usdSellingPriceTo = value;
          return (
            parseInt(usdSellingPriceTo, 10) >= parseInt(usdSellingPriceFrom, 10)
          );
        }
      )
      .required("Price To is required"),
    fixedUsdPayment: Yup.string().required("Fixed Usd Payment is required"),
    percentage: Yup.string().required("Percentage is required"),
  });
  return newSchema;
};

export const TransferInternalStateValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("State Name is required"),
    stateColour: Yup.string().required("State Colour is required"),
    stateCode: Yup.string().required("State Code is required"),
  });
  return newSchema;
};

export const StockTagInternalStateValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("State Name is required"),
    stateColour: Yup.string().required("State Colour is required"),
    stateCode: Yup.string().required("State Code is required"),
  });
  return newSchema;
};

export const OffersAndConditionValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    allowedPropertiesIds: Yup.array().required(
      "Select minimum one Allowed Properties."
    ),
    // allowedPropertiesIds: Yup.array().of(Yup.string().required("Select minimum one Allowed Properties.")),
  });
  return newSchema;
};

export const GiftVouchersValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    message: Yup.string().required("Message is required"),
    startDate: Yup.date()
      .required("Start date is required")
      .max(Yup.ref("endDate"), "Start date must be before end date"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
    recepientEmail: Yup.string()
      .email("Email is Invalid")
      .required("Email is required"),
    recepientName: Yup.string().required("Name is required"),
    amount: Yup.number().required("Amount is required"),
    storeId: Yup.string().required("Store is required"),
    issuedToCustomerId: Yup.string().required("Customer is required"),
  });
  return newSchema;
};

export const RepairRequestValidation = () => {
  const newSchema = Yup.object().shape({
    storeId: Yup.string().required("Store is required"),
    orderId: Yup.string().required("Order is required"),
    customerId: Yup.string().required("Customer is required"),
    shippingMethodId: Yup.string().required("Shipping Method is required")
  });
  return newSchema;
};

export const ReplacementRequestValidation = () => {
  const newSchema = Yup.object().shape({
    storeId: Yup.string().required("Store is required"),
    orderId: Yup.string().required("Order is required"),
    customerId: Yup.string().required("Customer is required"),
    shippingMethodId: Yup.string().required("Shipping Method is required")
  });
  return newSchema;
};

export const asmIncentiveValidation = () => {
  const newSchema = Yup.object().shape({
    percent: Yup.string().required("Percent is required"),
    orderLineItemId: Yup.string().required("Order Line Item is required"),
    orderId: Yup.string().required("Order is required"),
    userId: Yup.string().required("User is required"),
  });
  return newSchema;
};

export const activityMappingValidation = () => {
  const newSchema = Yup.object().shape({
    stoneQuantity: Yup.string().required("Stone Quantity is required"),
    leadTime: Yup.string().required("Lead Time is required"),
    cost: Yup.string().required("Cost is required"),
  });
  return newSchema;
};

export const brandValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required")
  });
  return newSchema;
};

export const companyValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  return newSchema;
};

export const importDutyValidation = () => {
  const newSchema = Yup.object().shape({
    importDuty: Yup.string().required("Import Duty is required"),
    fromcountryId: Yup.string().required("From Country is required")
    .test('notEqual', 'From Country and To Country should not be the same', function (value) {
      const { toCountryId } = this.parent;
      return value !== toCountryId;
    }),
  toCountryId: Yup.string().required("To Country is required")
    .test('notEqual', 'To Country and From Country should not be the same', function (value) {
      const { fromcountryId } = this.parent;
      return value !== fromcountryId;
    }),
    goodsCategoryId: Yup.string().required("Goods Category is required"),
  });
  return newSchema;
};



export const inventoryTagValidation = (missingComponents) => {
  const newSchema = Yup.object().shape({
    // semiFgProducts: Yup.object().shape({
    //   missingComponents: Yup.array().of(
    //     Yup.object().shape({
    //       missingStones: Yup.array().of(
    //         Yup.object().shape({
    //           missingQty: Yup.number()
    //             .max(
    //               Yup.ref('qty'),
    //               'Missing quantity must be less than or equal to stone quantity'
    //             )
    //         })
    //       )
    //     })
    //   )
    // })
  });

  return newSchema;
};

export const InventoryTagInternalStateValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("State Name is required"),
    stateColour: Yup.string().required("State Colour is required"),
    stateCode: Yup.string().required("State Code is required"),
  });
  return newSchema;
};

export const PaymentInternalStateValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("State Name is required"),
    stateColour: Yup.string().required("State Colour is required"),
    stateCode: Yup.string().required("State Code is required"),
  });
  return newSchema;
};

export const CalendarValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Operator Name is required"),
  });
  return newSchema;
};

export const ActivityLocationMappingValidation = () => {
  const newSchema = Yup.object().shape({
    activityMasterId: Yup.string().required("Activity is required"),
    locationId: Yup.string().required("Locaiton is required"),
    calendarId: Yup.string().required("Calendar Selection is required"),
  });
  return newSchema;
};

export const ActivityCalendarValidation = () => {
  const newSchema = Yup.object().shape({
    day: Yup.number().required("Day is required"),
    month: Yup.number().required("Month is required"),
    year: Yup.number().required("Year is required"),
    calendarId: Yup.string().required("Calendar Selection is required"),
  });
  return newSchema;
};

export const appointmentValidation = () => {
  const newSchema = Yup.object().shape({
    phone: Yup.string().matches(
      /^\d{10}$/,
      "Phone number must be exactly 10 digits"
    ),
    locationId: Yup.string().required("Location is required"),
    storeId: Yup.string().required("Store is required"),
    userId: Yup.string().required("User is required"),
    date: Yup.string().required("Date is required"),
    // time: Yup.string().required("Time is required"),
    durationInMinutes: Yup.string().required("Duration In Minutes is required"),
    appointmentTypeId: Yup.string().required("Appointment Type is required"),
    appointmentModeId: Yup.string().required("Appointment Mode is required"),
  });
  return newSchema;
};

export const appointmentModeValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    type: Yup.string().required("Type is required"),
    description: Yup.string().required("Description is required"),
    // appointmentModeTranslates: Yup.string().required("User is required"),
  });
  return newSchema;
};

export const appointmentTypeValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    availableAppointmentModeIds: Yup.array().required(
      "Available Appointment Mode is required"
    ),
    description: Yup.string().required("Description is required"),
    durationInMinutes: Yup.string().required("Duration In Minutes is required"),
    emailTemplate: Yup.string().email().required("Email is required"),
  });
  return newSchema;
};

export const AppointmentInternalStateValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("Name is required"),
    stateColour: Yup.string().required("Colour is required"),
  });
  return newSchema;
};

export const ReconciliationDetailsValidation = () => {
  const newSchema = Yup.object().shape({
    locationId: Yup.string().required("Location is required"),
    reconciliationTypeId: Yup.string().required(
      "Reconciliation Type is required"
    ),
  });
  return newSchema;
};

export const StockReconcilationValidation = () => {
  const newSchema = Yup.object().shape({
    locationId: Yup.string().required(
      "Location is required"
    ),
  });
  return newSchema;
};

export const InventoryTagReconciliationValidation = () => {
  const newSchema = Yup.object().shape({
    foundGoodQuantity: Yup.string().required("Found Good Quantity is required"),
    foundDamagedQuantity: Yup.string().required(
      "Found Damaged Quantity is required"
    ),
    lostQuantity: Yup.string().required("Lost Quantity is required"),
    noteText: Yup.string().required("Notes is required"),
    inventoryTagId: Yup.string().required("Inventory Tag is required"),
    inventoryTagReconciliationStateId: Yup.string().required(
      "Inventory Tag Reconciliation State is required"
    ),
  });
  return newSchema;
};

export const OrderLineItemDemandFullProductValidation = () => {
  const newSchema = Yup.object().shape({
    jewelleryProductId: Yup.string().required("Jewellery Product is required"),
    orderLineItemDemandFullProductStateId: Yup.string().required(
      "Full Product State is required"
    ),
  });
  return newSchema;
};

export const OrderDemandLineItemStockProductValidation = () => {
  const newSchema = Yup.object().shape({
    stockTagId: Yup.string().required("Stock Tag is required"),
    orderDemandLineItemStockProductStateId: Yup.string().required(
      "Stock Product State is required"
    ),
  });
  return newSchema;
};

export const OrderLineItemDemandVendorProductValidation = () => {
  const newSchema = Yup.object().shape({
    productId: Yup.string().required("Stock Tag is required"),
    orderDemandLineItemVendorProductStateId: Yup.string().required(
      "Stock Product State is required"
    ),
  });
  return newSchema;
};

export const OrderLineItemDemandServiceProductValidation = () => {
  const newSchema = Yup.object().shape({
    productId: Yup.string().required("Stock Tag is required"),
    orderDemandLineItemServiceStateId: Yup.string().required(
      "Service State is required"
    ),
  });
  return newSchema;
};

export const BankDepositeValidation = () => {
  const newSchema = Yup.object().shape({
    bankDepositDetails: Yup.object().shape({
      userId: Yup.number().required("User is required"),
      amount: Yup.number().required("Amount is required"),
      receiptNumber: Yup.string().required("Receipt Number is required"),
      depositedDate: Yup.string().required("Deposited Date is required"),
      companyMerchantAccount: Yup.string().required("Company Merchant Account"),
    })
  });
  return newSchema;
};

export const DemandStateValidation = () => {
  const newSchema = Yup.object().shape({
    stateName: Yup.string().required("Name is required"),
    stateColour: Yup.string().required("Colour is required"),
    stateCode: Yup.string().required("Code is required"),
  });
  return newSchema;
};
export const currencyRatesFormValidation = () => {
  const newSchema = Yup.object().shape({
    value: Yup.number().required("Value is required"),
    baseValue: Yup.number().required("Base Value is required"),
    markup: Yup.number().required("Markup is required"),
  });
  return newSchema;
};

export const vendorFormValidation = () => {
  const newSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    vendor_code: Yup.string().required("Vendor Code is required"),
    vendor_number: Yup.string().required("Vendor Name is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    preference: Yup.string().required("Preference is required"),
    IEC_Code: Yup.string().required("IEC Code is required"),
  });
  return newSchema;
};

export const PoLineItemDetailValidation = () => {
  const newSchema = Yup.object().shape({
    quantity: Yup.string().required("Quantity is required"),
    poLineItemId: Yup.string().required("Po Line Item is required"),
    sourcePoLineItemId: Yup.string().required("Source Po Line Item is required"),
  });
  return newSchema;
};


export const addressValidation = () => {
  const newSchema = Yup.object().shape({
    email: Yup.string().nullable().email()
  });

  return newSchema;
};

export const AssignUserValidation = () => {
  const newSchema = Yup.object().shape({
    estimatedTime: Yup.date().required("Estimated date is required"),
    modelAssignedTo: Yup.string().required("Assign To is required"),
    notes: Yup.string().required("Notes is required"),
  });
  return newSchema;
};

export const scheduleValidation = () => {
  const newSchema = Yup.object().shape({
    schedule: Yup.object().shape({
      description: Yup.string().required("Description is required"),
      name: Yup.string().required("Name is required"),
    })
  });
  return newSchema;
};

export const ShipmentTrackingValidation = () => {
  const newSchema = Yup.object().shape({
    senderLocationId: Yup.string().required('Sender Location is required'),
    receivingLocationId: Yup.string().required('Receiving Location is required')
      .test(
        'not-same-as-sender',
        'Receiving Location cannot be the same as Sender Location.',
        function (value) {
          return value !== this.parent.senderCompanyId;
        }
      ),
  });

  return newSchema;
};

export const CoponentDemandValidation = () => {
  const newSchema = Yup.object().shape({
    componentTypeId: Yup.string().required("Component Type is required"),
  });
  return newSchema;
};